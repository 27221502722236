import { graphql, useStaticQuery } from "gatsby";
import gsap from "gsap";
import React from "react";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { GatsbyImage } from "gatsby-plugin-image";

const PortfolioSection = ({ page, description, title }) => {
	const data = useStaticQuery(graphql`
		query {
			allWpPortfolioCard {
				nodes {
					portfolioCardFields {
						colour
						url
						page
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					title
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const enterAnimation = (text) => {
		gsap.to(
			text,

			{ opacity: 1, duration: 0.5 }
		);
	};
	const exitAnimation = (text) => {
		gsap.to(
			text,

			{ opacity: 0, duration: 0.5 }
		);
	};

	const desc =
		description ||
		"Want to see examples of our work and other websites we've created? Check out our portfolio below.";

	return (
		<Container>
			<Row>
				<Col className="text-center pb-5  pb-md-7">
					{!title && <h2 className="display-5 text-primary mb-5">TRUSTED BY</h2>}
					{title && <div>{title}</div>}
					<p>{desc}</p>
				</Col>
			</Row>
			<Row className="g-5 justify-content-center d-none d-md-flex">
				{data.allWpPortfolioCard.nodes
					.filter((item, index) => item.portfolioCardFields.page.includes(page))
					.map((port, index) => (
						<Col md={6} lg={4}>
							<a
								className="text-decoration-none"
								target="_blank"
								rel="noreferrer"
								href={port.portfolioCardFields.url}
							>
								<div
									style={{
										boxShadow: `0px 3px 99px ${port.portfolioCardFields.colour}70`,
										maxHeight: "25rem",
										overflow: "hidden",
										borderRadius: "15px",
									}}
									className="position-relative h-100 w-100"
								>
									<div
										style={{
											background:
												"transparent linear-gradient(90deg, #023A7FD9 0%, #023A7FD9 100%) 0% 0% no-repeat padding-box",
											zIndex: 3,
										}}
										onMouseEnter={() => enterAnimation(`.port-hover-${index}`)}
										onMouseLeave={() => exitAnimation(`.port-hover-${index}`)}
										className={`position-absolute d-flex justify-content-center align-items-center port-hover-${index}  w-100 h-100`}
									>
										<div className="d-block text-center">
											<h2 className="ssp-bold text-white w-100 display-5">
												{port.title}
											</h2>
											<Button
												className="mt-5 w-100 w-md-auto cta-btn fs-5 white-link  py-2 px-4 "
												target="_blank"
												rel="noreferrer"
												href={port.portfolioCardFields.url}
												style={{
													backgroundColor: port.portfolioCardFields.colour,
													border: "0px",
													borderRadius: "11px",
												}}
											>
												VISIT LIVE SITE
											</Button>
										</div>
									</div>
									<GatsbyImage
										image={port.portfolioCardFields.image.gatsbyImage}
										alt={port.portfolioCardFields.image.altText}
										objectPosition="top center"
									/>
								</div>
							</a>
						</Col>
					))}
			</Row>
			<Row className="d-md-none">
				<Col>
					<div
						onMouseEnter={() => enterAnimation(`.port-hover-1`)}
						onMouseLeave={() => exitAnimation(`.port-hover-1`)}
					>
						<Carousel interval={3000}>
							{data.allWpPortfolioCard.nodes
								.filter((item, index) =>
									item.portfolioCardFields.page.includes(page)
								)
								.map((port, index) => (
									<Carousel.Item>
										<a
											className="text-decoration-none"
											target="_blank"
											rel="noreferrer"
											href={port.portfolioCardFields.url}
										>
											<div>
												<div
													style={{
														maxHeight: "25rem",
														overflow: "hidden",
													}}
													className="position-relative  h-100 w-100"
												>
													<div
														style={{
															background:
																"transparent linear-gradient(90deg, #023A7FD9 0%, #023A7FD9 100%) 0% 0% no-repeat padding-box",
															zIndex: 1,
														}}
														className={`position-absolute d-flex justify-content-center align-items-center port-hover-1  w-100 h-100`}
													>
														<div className="d-block text-center">
															<h2 className="ssp-bold text-white w-100 display-5">
																{port.title}
															</h2>
															<Button
																className="mt-5 w-100 w-md-auto cta-btn fs-5 white-link  py-2 px-4 "
																target="_blank"
																rel="noreferrer"
																href={port.portfolioCardFields.url}
																style={{
																	backgroundColor:
																		port.portfolioCardFields.colour,
																	border: "0px",
																	borderRadius: "11px",
																}}
															>
																VISIT LIVE SITE
															</Button>
														</div>
													</div>
													<GatsbyImage
														image={port.portfolioCardFields.image.gatsbyImage}
														alt={port.portfolioCardFields.image.altText}
														objectPosition="top center"
													/>
												</div>
											</div>
										</a>
									</Carousel.Item>
								))}
						</Carousel>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default PortfolioSection;
